<template>
  <b-row>
    <b-colxx xxs="12" lg="12" md="12" xl="12">
      <b-card class="mb-4" :title="title">
        <b-form
          @submit.stop.prevent="onValidateEmailTemplateFormSubmit"
          class="av-tooltip tooltip-label-top"
        >
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Subject">
                <b-form-input
                  type="text"
                  v-model="$v.emailTemplate.subject.$model"
                  :state="validateEmailTemplateState('subject')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.emailTemplate.subject.required"
                  >Please enter Subject</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-else-if="!$v.emailTemplate.subject.maxLength"
                  >Maximum Characters Allowed 30</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="12">
              <b-form-group label="Body">
                <editor
                  api-key="no-api-key"
                  v-model="emailTemplate.body"
                  :state="validateEmailTemplateState('body')"
                  :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | code | help'
                  }"
                />
              </b-form-group>
              <b-form-invalid-feedback v-if="!$v.emailTemplate.body.required"
                >Please enter Body</b-form-invalid-feedback
              >
            </b-colxx>
          </b-row>
          <b-row align-h="left">
            <b-colxx xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelEmailTemplateForm()"
                >{{ $t('forms.cancel') }}
              </b-button>
            </b-colxx>
            <b-colxx lg="4" xl="2">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t('forms.submit') }}
              </b-button>
            </b-colxx>
          </b-row>
        </b-form>
      </b-card>
    </b-colxx>
  </b-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import Switches from 'vue-switches'
import EmailTemplateMixin from '../../../../mixins/EmailTemplateMixin.vue'
import Editor from '@tinymce/tinymce-vue'
const {
  required,
  maxLength,
  minLength,
  numeric
} = require('vuelidate/lib/validators')

export default {
  components: {
    editor: Editor
  },

  props: {
    emailTemplateID: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      title: null
    }
  },
  async created () {
    this.title = 'Edit Template'
    await this.getEmailTemplateById(this.emailTemplateID)
  },
  mixins: [validationMixin, EmailTemplateMixin],
  validations: {
    emailTemplate: {
      subject: {
        required,
        maxLength: maxLength(150)
      },
      body: {
        // maxLength: maxLength(500)
      }
    }
  },
  methods: {
    validateEmailTemplateState (name) {
      const { $dirty, $error } = this.$v.emailTemplate[name]
      return $dirty ? !$error : null
    },
    onValidateEmailTemplateFormSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.saveEmailTemplate(this.emailTemplate)
      }
    },
    async saveEmailTemplate (emailTemplate) {
      await this.updateEmailTemplate(emailTemplate)
      if (this.statusCode === 200) {
        this.$router.push({ name: 'emailTemplatesTable' })
      }
    },
    cancelEmailTemplateForm () {
      this.$router.push({ name: 'emailTemplatesTable' })
    }
  },
  computed: {}
}
</script>

<style scoped>
.form-control.is-invalid {
  border-color: #dc3545;
}
</style>
