var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-colxx',{attrs:{"xxs":"12","lg":"12","md":"12","xl":"12"}},[_c('b-card',{staticClass:"mb-4",attrs:{"title":_vm.title}},[_c('b-form',{staticClass:"av-tooltip tooltip-label-top",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onValidateEmailTemplateFormSubmit($event)}}},[_c('b-row',[_c('b-colxx',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Subject"}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.validateEmailTemplateState('subject')},model:{value:(_vm.$v.emailTemplate.subject.$model),callback:function ($$v) {_vm.$set(_vm.$v.emailTemplate.subject, "$model", $$v)},expression:"$v.emailTemplate.subject.$model"}}),(!_vm.$v.emailTemplate.subject.required)?_c('b-form-invalid-feedback',[_vm._v("Please enter Subject")]):(!_vm.$v.emailTemplate.subject.maxLength)?_c('b-form-invalid-feedback',[_vm._v("Maximum Characters Allowed 30")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-colxx',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Body"}},[_c('editor',{attrs:{"api-key":"no-api-key","state":_vm.validateEmailTemplateState('body'),"init":{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | help'
                }},model:{value:(_vm.emailTemplate.body),callback:function ($$v) {_vm.$set(_vm.emailTemplate, "body", $$v)},expression:"emailTemplate.body"}})],1),(!_vm.$v.emailTemplate.body.required)?_c('b-form-invalid-feedback',[_vm._v("Please enter Body")]):_vm._e()],1)],1),_c('b-row',{attrs:{"align-h":"left"}},[_c('b-colxx',{attrs:{"xl":"2","lg":"4"}},[_c('b-button',{staticClass:"mt-4",attrs:{"block":"","variant":"danger","size":"lg"},on:{"click":function($event){return _vm.cancelEmailTemplateForm()}}},[_vm._v(_vm._s(_vm.$t('forms.cancel'))+" ")])],1),_c('b-colxx',{attrs:{"lg":"4","xl":"2"}},[_c('b-button',{staticClass:"mt-4",attrs:{"block":"","type":"submit","variant":"success","size":"lg"}},[_vm._v(_vm._s(_vm.$t('forms.submit'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }